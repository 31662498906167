import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import HeaderModule from "../modules/header";
import FooterModule from "../modules/footer";

import HomePage from "../pages/home";
import Demo1Page from "../pages/demo1";
import React from "react";
import './index.css'

export default function LayOut() {

  return (
    <div className={'page-container'}>
      <div className={'page-head-container'}>
        <HeaderModule />
      </div>
      <div className={'page-body-container'}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/index" element={<HomePage />} />
          <Route path="/case-demo" element={<Demo1Page />} />
        </Routes>
      </div>
      <div className={'page-foot-container'}>
        <FooterModule />
      </div>

    </div>
  )
}
