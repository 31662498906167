import React, {useState, useEffect} from "react";

const TextToSpeech = ({text}) => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    const vioce = synth.getVoices();
    setUtterance(u);
    u.voice = vioce[1];
    synth.speak(u);

    return () => {
      synth.cancel();
    };
  }, [text]);
};

export default TextToSpeech;
