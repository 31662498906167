import React from "react";
import {BrowserRouter as Router, Route, Routes, Switch} from "react-router-dom";
import ChatPage from "../pages/chat";
// import HomePage from "../pages/home";
import LayOut from "../layout";
import ImageGen from "../pages/imageGen";
import VideoGen from "../pages/videoGen";

const BasicRouter = () => {
  return (
    <Router>
      <Routes>
        {/*<Route path="/" element={<HomePage />} />*/}
        {/*<Route path="/home" element={<HomePage />} />*/}
        {/*<Route path="/index" element={<HomePage />} />*/}
        <Route path="/ai-agent/demo/proptech" element={<ChatPage />} />
        <Route path="/ai-agent/demo/image" element={<ImageGen />} />
        <Route path="/ai-agent/demo/video" element={<VideoGen />} />
        <Route path="/*" element={<LayOut />} />
      </Routes>
    </Router>
  );
};

export default BasicRouter;
