import React from "react";
import Logo from "../../assets/images/visios-logo.png";
import "./index.css";
import axios from "axios";
import LoadingGif from "../../assets/images/loadinggif.gif";
import simpleImage from "../../assets/images/solution.png";
import {v4 as uuidv4} from "uuid";

function VideoGen() {
  const [imgSrc, setImgSrc] = React.useState(simpleImage);
  const [imageFile, setImageFile] = React.useState(null);
  const [videoSrc, setVideoSrc] = React.useState(null);
  const [userInput, setUserInput] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [uploadId, setUploadId] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [fileSize, setFileSize] = React.useState("");
  const [fileType, setFileType] = React.useState("");
  const [path, setPath] = React.useState("");
  const generateVideo = async () => {
    setLoading(true);
    //when image present use video gen endpoint
    if (imageFile) {
      console.log("generate video with image");
      const data = new FormData();
      data.append("image", imageFile);
      data.append("prompt", userInput);
      const res = await axios.post("/generate_video/", data, {
        responseType: "arraybuffer",
      });
      const videoBlob = new Blob([new Uint8Array(res.data)], {type: "video/mp4"});
      const videoUrl = URL.createObjectURL(videoBlob);
      setVideoSrc(videoUrl);
    } else {
      //use edward's endpoint without image
      console.log("generate video without image");
      const data = {
        prompt: userInput,
      };
      const res = await axios.post("/generate_text_to_video", data, {
        responseType: "arraybuffer",
      });
      const gifBlob = new Blob([res.data], {type: "image/gif"});
      const url = URL.createObjectURL(gifBlob);
      setImgSrc(url);
    }

    setLoading(false);
  };

  const handleUserInput = e => {
    setUserInput(e.target.value);
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const imageUrl = URL.createObjectURL(file);
      const fileName = file.name;
      const fileSize = file.size;
      const fileType = file.type;
      setFileType(fileType);
      setFileName(fileName);
      setFileSize(fileSize);
      setUploadedImage(imageUrl);
      alert("Image uploaded successfully!");
    }
  };

  return (
    <div className="imageContainer">
      <div className="image-header">
        <div className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <div className="image-buttons">
          <button className="image-button">History</button>
          <button className="image-button">Share</button>
          <button className="image-button save-button">Save</button>
        </div>
      </div>
      <div className="image-body">
        <div className="image-body-left">
          <h2>Generate Video</h2>
          <input type="file" onChange={handleImageUpload} />
          {uploadedImage && (
            <div className="uploaded-image-container">
              <p>Uploaded Image:</p>
              <img src={uploadedImage} alt="Uploaded" className="uploaded-image" />
            </div>
          )}
          <p>Describe what you'd like to generate:</p>
          <textarea className="image-input" placeholder="Input prompts" onChange={handleUserInput}></textarea>

          <button className="image-generate-button" onClick={generateVideo}>
            Generate
          </button>
        </div>
        <div className="image-body-right">
          <p style={{marginBottom: "16px"}}>Your Video</p>
          <div className="result">{videoSrc ? <video src={videoSrc} alt="Generated" className="generated-image" controls /> : <img src={imgSrc} alt="Generated" className="generated-image" />}</div>
        </div>

        {loading && (
          <div className="loadingScreen">
            <div>
              <img src={LoadingGif} alt="Loading" className="loading-image" />
            </div>
          </div>
        )}
      </div>
      {/* <iframe src="/video-gen" width="100%" height="100%" title="Iframe Example"></iframe> */}
    </div>
  );
}

export default VideoGen;
