import './index.css'
import IconLogo from '../../assets/images/visios-logo.png'
import IconList from '../../assets/images/icon-list.png'

export default function FooterModule(){

  return <>
    <div className="footer-box">
      <div className="main-box">
        <div className="box-nav">
          <div className="box-contactus">
            <div className="item-logo">
              <a href="/"><img src={IconLogo} alt="LOGO"/></a>
              <p style={{marginBottom: '10px', display: 'none'}}>CONTACT US</p>
            </div>
            <div className="item-link" style={{marginTop: '25px'}}>
              <img style={{width: '60%', maxWidth: '215px'}} src={IconList}/>
            </div>
            <div className="item-link" style={{display: 'none'}}>
              <p><span style={{color: '#16adfd'}}>Phone:</span> 217-903-5860</p>
              <p>
                <span style={{color: '#16adfd'}}>Media:</span>
                media@smartz.cloud
              </p>
              <p>
                <span style={{color: '#16adfd'}}>Business:</span>
                partner@smartz.cloud
              </p>
            </div>
          </div>
          <div className="list-link">

            <div className="link-item">
              <p className="link-title">
                Support
              </p>
              <ul className="linklist">
                <li><a href="">FAQ</a></li>
                <li><a href="">Get Help</a></li>
                <li><a href="">Order Track</a></li>
              </ul>
            </div>
            <div className="link-item">
              <p className="link-title">
                Get Involved
              </p>
              <ul className="linklist">
                <li><a href="">Joint the Community</a></li>
                <li><a href="">Partnership Program</a></li>
                <li><a href="">Terms of Services</a></li>
                <li><a href="">Privacy Policy</a></li>
              </ul>
            </div>
            <div className="link-item">
              <p className="link-title">
                Company
              </p>
              <ul className="linklist">
                <li><a href="">About</a></li>
                <li><a href="">Contact</a></li>
                <li><a href="">Careers</a></li>
                <li><a href="">Blog</a></li>
              </ul>

            </div>

          </div>
        </div>


        <div className="box-copyright">
          <p>Copyright © 2024 Visios.ai All rights reserved</p>
        </div>
      </div>
    </div>
  </>
}
