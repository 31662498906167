import "./index.css";

import Banner_1 from "../../assets/images/home/banner-1.png";

import Banner_2_2 from "../../assets/images/home/2-2.png";
import Banner_2_3 from "../../assets/images/home/2-3.png";
import Banner_2_4 from "../../assets/images/home/2-4.png";
import solution from "../../assets/images/solution.png";
import VideoFile_2_1 from "../../assets/video/3_AI_chat.webm";
import VideoFile_1_1 from "../../assets/video/1_Banner.webm";
import VideoFile from "../../assets/video/4.webm";
const IconRa = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" fill="white" fill-opacity="0.01" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0643 4.875C18.4786 4.875 18.8143 5.21079 18.8143 5.625L18.8143 12.375C18.8143 12.7892 18.4786 13.125 18.0643 13.125C17.6501 13.125 17.3143 12.7892 17.3143 12.375L17.3143 7.43566L5.86675 18.8833C5.57385 19.1761 5.09898 19.1761 4.80609 18.8833C4.51319 18.5904 4.51319 18.1155 4.80609 17.8226L16.2537 6.375L11.3143 6.375C10.9001 6.375 10.5643 6.03921 10.5643 5.625C10.5643 5.21079 10.9001 4.875 11.3143 4.875L18.0643 4.875Z"
      fill="#000000"
    />
  </svg>
);
const IconRw = (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.66667 0.108093L8.41667 1.35809L12.2253 5.16669H0.5V6.83335H12.2253L8.41667 10.6419L9.66667 11.8919L15.5586 6.00002L9.66667 0.108093Z" fill="currentColor"></path>
  </svg>
);

export default function HomePage() {
  return (
    <>
      <div className={"home-page"}>
        <section className={"part01"}>
          <div className={"main-box row-box"}>
            <div className={"words-box"}>
              <h1 className={"mgb-50"}>Visios AI Platforms</h1>
              <p className={"mgb-20"}>
                Visios (Vector Integration of Synthesis, Inference, and Optimization System)
                AI Platforms leverage AI capabilities, enterprises can streamline operations,
                reduce costs, and improve overall efficiency. AI Platforms offer flexible solutions
                that can be integrated into existing systems, allowing businesses to harness the
                power of AI without extensive technical expertise or infrastructure upgrades.
              </p>
              {/*/case-demo  https://visios.ai/agent/creator/*/}
              <a href={"/case-demo"} target={"_blank"} style={{textDecoration: "none", border: "none"}}>
                <button className={"a-btn"} style={{width: "85%", height: "45px", fontSize: '16px'}}>
                  Book a Demo
                  <img style={{width: "16px", marginLeft: "10px"}} src="https://cdn.prod.website-files.com/5e2f1a38fabf808ee0fece04/64c7aa5ba4a97a87a4912ff4_arrow.svg" alt="" />
                </button>
              </a>
            </div>
            <div className={"media-box"}>
              {/*<img className={"w-100"} src={Banner_1} alt="" />*/}
              <video
                src={VideoFile_1_1}
                webkit-playsinline='true'
                playsInline='true'
                x5-video-player-type='h5'
                x5-video-player-fullscreen='true'
                x-webkit-airplay='true'
                loop={true}
                muted={true}
                autoPlay={true}
                style={{userSelect: 'none'}}
                className={'w-100'}
              ></video>
            </div>
          </div>
        </section>

        <section className={"part02"}>
          <div className={"main-box"}>

            <div className={"row-box row-box-rev"}>
              <div className={"words-box"}>
                <h1 className={"mgb-30"}>Visios AI Agent is Poised to Revolutionize Customer Support</h1>
                <p className={"mgb-30"}>
                  AI Agent is set to transform customer support by enhancing self-service capabilities through advanced chatbots and virtual assistants that understand and respond to queries in
                  natural language. It can significantly reduce resolution times by quickly analyzing vast amounts of data, while also personalizing interactions based on customer history and
                  preferences.
                </p>
                <button className={"a-btn"}>
                  Learn More <span>{IconRw}</span>
                </button>
              </div>
              <div className={"media-box"}>
                {/*<img className={"w-100"} src={Banner_1} alt="" />*/}
                <video
                  src={VideoFile_2_1}
                  webkit-playsinline='true'
                  playsInline='true'
                  x5-video-player-type='h5'
                  x5-video-player-fullscreen='true'
                  x-webkit-airplay='true'
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  style={{userSelect: 'none'}}
                  className={'w-100'}
                ></video>
              </div>
            </div>

            <div className={"row-box"}>
              <div className={"words-box"} style={{paddingTop: "20px"}}>
                <h1 className={"mgb-30"}>Visios AI Powered N/L Code APPs Creator</h1>
                <p className={"mgb-30"}>
                  AI-powered no/low-code creation of apps is revolutionizing software development by enabling users to create sophisticated applications without writing traditional code.
                </p>
                <button className={"a-btn"}>
                  Learn More <span>{IconRw}</span>
                </button>
              </div>
              <div className={"media-box"}>
                <img className={"w-100"} style={{width: '110%'}} src={Banner_2_2} alt="" />
              </div>
            </div>

            <div className={"row-box row-box-rev"}>
              <div className={"words-box"} style={{paddingTop: "10px"}}>
                <h1 className={"mgb-30"}>Visios AI Data Analytics</h1>
                <p className={"mgb-30"}>Visios AI data analytics empowered you to analyze and interpret complex datasets, enabling more efficient and insightful data analysis.</p>
                <button className={"a-btn"}>
                  Learn More <span>{IconRw}</span>
                </button>
              </div>
              <div className={"media-box"}>
                <img className={"w-100"} src={Banner_2_3} alt="" />
              </div>
            </div>

            <div className={"row-box"}>
              <div className={"words-box"}>
                <h1 className={"mgb-30"}>Visios AI Video Editing Tools</h1>
                <p className={"mgb-30"}>
                  Visios streamlines video editing with Al features like Muxmal auto-reframing,
                  adjusting videos for different aspect ratios, and scene edit detection,
                  quickly segmenting key moments. Coming soon, Al video generation will make content creation even easier,
                  revolutionizing how you edit and produce videos with minimal effort.
                </p>
                <button className={"a-btn"}>
                  Learn More <span>{IconRw}</span>
                </button>
              </div>
              <div className={"media-box"}>
                {/*<img className={"w-100"} src={Banner_2_4} alt="" />*/}
                <video
                  src={VideoFile}
                  webkit-playsinline='true'
                  playsInline='true'
                  x5-video-player-type='h5'
                  x5-video-player-fullscreen='true'
                  x-webkit-airplay='true'
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  style={{userSelect: 'none'}}
                  className={'w-100'}
                ></video>
              </div>
            </div>
          </div>
        </section>

        <section className={"part03"}>
          <div className={"main-box"}>
            {/* <h1 className={"mgb-50"}>Visios AI IOT Tools</h1>
            <p style={{width: "350px", fontSize: "18px"}}>Provides a suite of services for connecting and monitoring IoT devices, as well as tools for edge computing and AI/ML model deployment.</p> */}
            <img className={"w-100"} src={solution} alt="" />

            {/* <div className={"a-item ps-1"}>
                <h2 className={"mgb-20"}>Analytics</h2>
                <p>AI and machine learning capabilities allow for sophisticated analysis of IoT data to uncover insights and enable predictive modeling.</p>
              </div>
              <div className={"a-item ps-2"}>
                <h2 className={"mgb-20"}>Data Ingestion and Processing</h2>
                <p>Visios AI powered platforms can ingest and process massive volumes of data from IoT sensors and devices in real-time.</p>
              </div>
              <div className={"a-item ps-3"}>
                <h2 className={"mgb-20"}>Application Development</h2>
                <p>Provide tools to rapidly design, develop, and deploy IoT applications with AI capabilities.</p>
              </div>
              <div className={"a-item ps-4"}>
                <h2 className={"mgb-20"}>Device Management</h2>
                <p>AI IoT platforms enable organizations to securely connect, monitor, and manage large networks of IoT devices at scale.</p>
              </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
