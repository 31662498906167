import './index.css'
import IconLogo from '../../assets/images/visios-logo.png';
import {useEffect, useState} from "react";

import logoMain from '../../assets/images/visios.png'
import logoWhite from '../../assets/images/visios-white.png'

const IconRa = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <rect width="24" height="24" fill="white" fill-opacity="0.01"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0643 4.875C18.4786 4.875 18.8143 5.21079 18.8143 5.625L18.8143 12.375C18.8143 12.7892 18.4786 13.125 18.0643 13.125C17.6501 13.125 17.3143 12.7892 17.3143 12.375L17.3143 7.43566L5.86675 18.8833C5.57385 19.1761 5.09898 19.1761 4.80609 18.8833C4.51319 18.5904 4.51319 18.1155 4.80609 17.8226L16.2537 6.375L11.3143 6.375C10.9001 6.375 10.5643 6.03921 10.5643 5.625C10.5643 5.21079 10.9001 4.875 11.3143 4.875L18.0643 4.875Z" fill="#ffffff"/>
</svg>

const IconRb = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <rect width="24" height="24" fill="white" fill-opacity="0.01"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0643 4.875C18.4786 4.875 18.8143 5.21079 18.8143 5.625L18.8143 12.375C18.8143 12.7892 18.4786 13.125 18.0643 13.125C17.6501 13.125 17.3143 12.7892 17.3143 12.375L17.3143 7.43566L5.86675 18.8833C5.57385 19.1761 5.09898 19.1761 4.80609 18.8833C4.51319 18.5904 4.51319 18.1155 4.80609 17.8226L16.2537 6.375L11.3143 6.375C10.9001 6.375 10.5643 6.03921 10.5643 5.625C10.5643 5.21079 10.9001 4.875 11.3143 4.875L18.0643 4.875Z" fill="#000000"/>
</svg>

export default function HeaderModule(){
  const [headMove, setHeadMove] = useState(false)

  useEffect(() => {
    window.onscroll = function (e){
      let {top} = document.body.getBoundingClientRect()

      if(top < -400){
        setHeadMove(() => true)
      }else{
        setHeadMove(() => false)
      }

      // console.log(top)
    }

    return () => {
      window.onscroll = () => {}
    }
  }, [])

  return <>
    <header className={`${headMove ? 'header-box header-box-bg' : 'header-box'}`}>
      <div className={"mian-box"}>
        <div className={'logo-and-nemu-box'}>
          <div className={'logo'}>
            <a href="/" style={{textDecoration:'none'}} target={'_self'}>
              <img src={headMove ? logoWhite : logoMain} alt=""/>
            </a>

          </div>
          <div className={'menu-box'}>
            <div className={'menu-item'}>PRODUCTS</div>
            <div className={'menu-item'}>SOLUTIONS</div>
            <div className={'menu-item'}>INDUSTRY</div>
            <div className={'menu-item'}>PRICING</div>
          </div>
        </div>
        <div className={'login-and-other-box'}>
          <div className={'a-btn main-bg-color fs-color-fff'}>
            Login
            <span style={{width: '16px', marginLeft: '10px'}}>
              {IconRa}
            </span>
          </div>
          {/*https://visios.ai/agent/creator/*/}
          <a className={'a-btn out-border-a'} href={'/case-demo'} target={'_self'}>
            Book a Demo
            <span style={{width: '16px', marginLeft: '10px'}}>
              {IconRb}
            </span>
          </a>
        </div>
      </div>
    </header>
  </>
}
